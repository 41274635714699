<template>
  <MainTitleBold center :class="{ 'error-title': !underDev }">
    <span v-if="underDev"> {{ undeDevString }}, перезагрузите страницу </span>
  </MainTitleBold>
</template>

<script>
import MainTitleBold from "../components/base/MainTitleBold";
import loadingMixin from "../mixins/loadingMixin";
export default {
  name: "NotFound",
  components: { MainTitleBold },
  mixins: [loadingMixin],
  data: () => ({
    undeDevString: "Сайт временно не работает"
  }),
  async mounted() {
    this.$store.commit(
      "UPDATE_HEADER_IMAGE",
      require("@/assets/images/error.jpg")
    );
    let setString = "Страница не найдена";
    if (this.underDev) setString = this.undeDevString;
    this.$store.commit("UPDATE_HEADER_TITLE", setString);
    this.$store.commit("UPDATE_TAB_TITLE", setString);
  }
};
</script>

<style lang="scss">
.error-title {
  &::before {
    content: "404";
    cursor: pointer;
  }

  &:hover::before {
    content: ":(((";
    color: theme-color("warning");
  }

  &:active::before {
    content: ";)))";
    color: theme-color("success");
  }
}
</style>
