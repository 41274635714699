<template>
  <div class="text-center">
    <radio-buttons-input
      v-model="typeSignIn"
      :items="typesSignIn"
      button
      button-group
      one-value
      class="mb-4"
      v-if="typesSignIn && typesSignIn.length > 1"
    ></radio-buttons-input>
    <validation-observer ref="observer">
      <form @submit.prevent.stop="sendRequest">
        <div v-if="renderLogin">
          <base-input
            v-if="typeSignIn === 'phone'"
            v-model="phone"
            :label="$t('user.fields.phone')"
            type="tel"
            class="mb-2"
            required
            :validate-disabled="false"
          ></base-input>
          <base-input
            v-if="typeSignIn === 'email'"
            v-model="email"
            :label="$t('user.fields.email')"
            type="email"
            class="mb-2"
            required
            :validate-disabled="false"
          ></base-input>
        </div>
        <div v-if="restorePass">
          <div class="mb-4">
            <router-link
              v-if="changeRoute"
              :to="{ name: 'SignIn' }"
              class="text-secondary"
              >{{ $t("user.fields.authorize") }}</router-link
            >
            <a
              v-else
              href="#"
              class="text-secondary"
              @click.prevent="restorePass = false"
              >{{ $t("user.fields.authorize") }}</a
            >
          </div>
        </div>
        <div v-else>
          <base-input
            v-model="password"
            :label="$t('user.fields.password')"
            type="password"
            class="mb-2"
            required
            :validate-disabled="false"
          ></base-input>
          <div class="mb-4">
            <router-link
              v-if="changeRoute"
              :to="{ name: 'ResetPass' }"
              class="text-secondary"
              >{{ $t("user.fields.resetPass") }}</router-link
            >
            <a
              v-else
              href="#"
              class="text-secondary"
              @click.prevent="restorePass = true"
              >{{ $t("user.fields.resetPass") }}</a
            >
          </div>
        </div>
        <filled-button class="btn-wide" color="primary" type="submit">
          {{ $t("user.fields.send") }}
        </filled-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import BaseInput from "@/components/inputs/BaseInput";
import FilledButton from "@/components/buttons/FilledButton";
import RadioButtonsInput from "../inputs/RadioButtonsInput";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import API from "@/common/api/api.functions";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "SignInComponent",
  components: {
    RadioButtonsInput,
    FilledButton,
    BaseInput,
    ValidationObserver
  },
  mixins: [loadingMixin],
  props: {
    changeRoute: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    email: null,
    phone: null,
    password: null,
    typeSignIn: "email",
    typesSignIn: [
      {
        value: "email",
        label: "По электронной почте"
      }
      // {
      //   value: "phone",
      //   label: "По телефону"
      // }
    ],
    renderLogin: true
  }),
  computed: {
    ...mapGetters(["auth", "jwt", "user"]),
    restorePass: {
      get() {
        return this.$store.state.restorePass;
      },
      set(val) {
        this.$store.commit("UPDATE_RESTORE_PASS", val);
      }
    }
  },
  mounted() {
    if (this.auth) this.$router.push({ name: "Home" });
  },
  watch: {
    typeSignIn() {
      this.renderLogin = false;
      this.$nextTick(() => (this.renderLogin = true));
    }
  },
  methods: {
    ...mapActions(["signin"]),
    async sendRequest() {
      for (const nowRef of Object.values(this.$refs.observer.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observer.validate();
      if (!validation) {
        this.$toast.error("Исправьте ошибки!");
        let refs = this.$refs.observer.refs;
        for (const ref of Object.values(refs)) {
          if (ref.errors.length) {
            ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
            break;
          }
        }
        return;
      }

      if (!this.restorePass) {
        await this.signin({
          username: this[this.typeSignIn],
          password: this.password
        });
      } else {
        this.loading++;
        await API.resetPassword({
          [this.typeSignIn]: this[this.typeSignIn]
        })
          .then(() => {
            this.$toast.success("Вам придёт письмо на email");
            this.$router.push({ name: "Home" });
          })
          .catch(() => {
            this.$toast.error("Пользователь не найден");
          });
        this.loading--;
      }
    }
  },
  destroyed() {
    this.restorePass = false;
  }
};
</script>
