<template>
  <div :class="{ 'text-center': center }" class="title-bold mb-2">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: "TitleBold",
  props: {
    center: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
