<template>
  <div class="container">
    <span>
      <p>
        {{ i18n.t("toast.privateInfo[0]") }}
        <a
          :href="router.resolve({ name: 'PersonalData' }).href"
          class="link-light"
          @click.prevent="
            router.currentRoute.name !== 'PersonalData'
              ? router.push({ name: 'PersonalData' })
              : null
          "
          >{{ i18n.t("toast.privateInfo[1]") }}</a
        >{{ i18n.t("toast.privateInfo[2]") }}
        <a
          :href="router.resolve({ name: 'Policy' }).href"
          class="link-light"
          @click.prevent="
            router.currentRoute.name !== 'Policy'
              ? router.push({ name: 'Policy' })
              : null
          "
          >{{ i18n.t("toast.privateInfo[3]") }}</a
        >{{ i18n.t("toast.privateInfo[4]") }}
      </p>
      <p class="fw-bold">
        {{ i18n.t("toast.privateInfo[5]") }}
      </p>
    </span>
    <button class="btn btn-outline-light" @click.stop="clicked">
      {{ i18n.t("toast.privateInfo[6]") }}
    </button>
  </div>
</template>

<script>
import router from "@/router";
import i18n from "@/i18n";

export default {
  name: "PrivateInformation",
  data: () => ({
    router,
    i18n
  }),
  props: {
    toastId: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    clicked() {
      localStorage.setItem("privateInformation", true);
      this.$emit("close-toast");
    }
  }
};
</script>
