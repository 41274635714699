<template>
  <validation-provider
    :name="uuid"
    :rules="required ? { required: true } : null"
    :customMessages="$store.state.validatorMessages"
    ref="validationProvider"
    v-slot="validationState"
    mode="lazy"
    tag="div"
    :detect-input="false"
    :skip-if-empty="false"
    :disabled="validateDisabled"
  >
    <template v-if="select">
      <label
        :class="{
          'd-block': !inlineLabel,
          'text-success': validationState.dirty && validationState.valid,
          'text-danger': validationState.dirty && !validationState.valid
        }"
        @click="$refs.sel.click()"
      >
        <slot name="label">{{ label }}</slot>
        <span class="text-danger" v-if="required">*</span>
        <SvgIcon
          color="current"
          class="ms-1"
          v-if="table"
          icon="table"
          height="16"
        ></SvgIcon>
        <SvgIcon
          color="current"
          class="ms-1"
          v-if="eventTypeRequired"
          icon="box-alt"
          height="16"
        ></SvgIcon>
      </label>
      <select
        class="form-select"
        :disabled="disabled"
        :class="{
          'is-valid': validationState.dirty && validationState.valid,
          'is-invalid': validationState.dirty && !validationState.valid
        }"
        ref="sel"
        @change="e => $emit('input', outputMixin(e.target.value))"
      >
        <option
          v-for="(item, key) in filteredItems"
          :key="key"
          :value="item.value"
          :selected="innerValue === item.value"
        >
          {{ item.label }}
        </option>
      </select>
    </template>
    <template v-else>
      <label
        :class="{
          'd-block': !inlineLabel,
          'text-success': validationState.dirty && validationState.valid,
          'text-danger': validationState.dirty && !validationState.valid
        }"
      >
        <slot name="label">{{ label }}</slot>
        <span class="text-danger" v-if="required">*</span>
        <SvgIcon
          color="current"
          class="ms-1"
          v-if="table"
          icon="table"
          height="16"
        ></SvgIcon>
        <SvgIcon
          color="current"
          class="ms-1"
          v-if="eventTypeRequired"
          icon="box-alt"
          height="16"
        ></SvgIcon>
      </label>
      <div
        :class="{
          'd-flex': inline,
          [`justify-content-${inline}`]: inline && inline !== true,
          [`btn-group${inline ? '' : '-vertical'}`]: buttonGroup,
          'w-100': !inline
        }"
        role="group"
      >
        <template v-if="button">
          <button
            v-for="(item, key) in filteredItems"
            :key="key"
            class="btn"
            type="button"
            :class="{
              ['d-none']: !button,
              [`btn-${color}`]: color,
              active: innerValue === item.value
            }"
            :disabled="disabled"
            @click="$emit('input', outputMixin(item.value))"
          >
            {{ item.label }}
          </button>
        </template>
        <div
          v-else
          class="form-check"
          :class="{ 'form-check-inline': inline }"
          v-for="(item, key) in filteredItems"
          :key="key"
        >
          <label
            class="form-check-label"
            :class="{
              'text-success': validationState.dirty && validationState.valid,
              'text-danger': validationState.dirty && !validationState.valid
            }"
          >
            <input
              type="radio"
              class="form-check-input"
              :class="{
                'is-valid': validationState.dirty && validationState.valid,
                'is-invalid': validationState.dirty && !validationState.valid
              }"
              :checked="innerValue === item.value"
              @input="$emit('input', outputMixin(item.value))"
              :disabled="disabled"
            />
            {{ item.label }}
          </label>
        </div>
      </div>
    </template>
    <div
      v-for="(error, key) in validationState.dirty
        ? validationState.errors
        : []"
      class="invalid-feedback d-block"
      :key="key"
    >
      {{ error }}
    </div>
    <div class="form-text">
      <slot name="help">{{ help }}</slot>
    </div>
  </validation-provider>
</template>

<script>
import SvgIcon from "../base/SvgIcon";
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { v4 as uuidv4 } from "uuid";

extend("required", required);

export default {
  name: "RadioButtonsInput",
  components: { SvgIcon, ValidationProvider },
  props: {
    help: {
      type: String
    },
    required: {
      type: [Boolean, Number],
      default: () => false
    },
    table: {
      type: Boolean,
      default: () => false
    },
    eventTypeRequired: {
      type: Boolean,
      default: () => false
    },
    items: Array,
    value: null,
    inline: {
      type: Boolean,
      default: () => false
    },
    inlineLabel: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      required: false
    },
    select: {
      type: Boolean,
      default: () => false
    },
    button: {
      type: Boolean,
      default: () => false
    },
    buttonGroup: Boolean,
    color: {
      type: String,
      default: () => "outline-primary brand-style"
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    validateDisabled: {
      type: Boolean,
      default: () => true
    },
    oneValue: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    uuid: uuidv4()
  }),
  computed: {
    innerValue() {
      if (this.oneValue) return this.value;
      return this.value?.length ? this.value[0] : undefined;
    },
    filteredItems() {
      return (this.items || []).filter(val => !val.deleted);
    }
  },
  methods: {
    outputMixin(val) {
      if (this.oneValue) return val;
      return [val];
    }
  },
  watch: {
    value(val) {
      if (!this.validateDisabled) {
        this.$refs.validationProvider.validate(val);
        this.$refs.validationProvider.setFlags({ dirty: true });
      }
    }
  },
  mounted() {
    if (!this.validateDisabled) {
      this.$refs.validationProvider.validate(this.value || []);
    }
  }
};
</script>
