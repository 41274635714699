<template>
  <transition name="short-fade">
    <a
      class="to-top d-flex flex-column align-items-center justify-content-center"
      v-if="scrollNow > 300"
      href="#"
      :style="buttonStyle"
      @click.prevent="$store.dispatch('scrollToTop')"
      ref="button"
    >
      <!--      <div class="mb-2">-->
      <!--        {{ JSON.stringify(footerBoundingSize) }}-->
      <!--      </div>-->
      <div class="button">
        <div class="d-flex align-items-center justify-content-center">
          <SvgIcon width="20" icon="arrow-up" />
        </div>
      </div>
    </a>
  </transition>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import scrollMixin from "@/mixins/scrollMixin";
import { debounce } from "lodash";

export default {
  name: "ToTopButton",
  mixins: [scrollMixin],
  data: () => ({
    buttonSize: null,
    buttonStyle: null
  }),
  components: {
    SvgIcon
  },
  methods: {
    updateCustomPadding() {
      const val = this.$refs.button?.getBoundingClientRect() || null;
      if (!val) {
        const buttonStyle = {
          paddingBottom: 0 + "px"
        };
        if (
          !this.buttonStyle ||
          JSON.stringify(buttonStyle) !== JSON.stringify(this.buttonStyle)
        )
          this.buttonStyle = buttonStyle;
        // console.log("first return", JSON.stringify(this.buttonStyle));
        return;
      }
      const footerY = this.footerBoundingSize?.y || null;
      const buttonBottom = val?.bottom || null;
      if (!footerY || !buttonBottom) return;
      const distance = footerY - buttonBottom;
      const padding = 15 - distance || 0;
      const buttonStyle = {
        paddingBottom: (padding < 0 ? 0 : padding) + "px"
      };
      if (
        !this.buttonStyle ||
        JSON.stringify(buttonStyle) !== JSON.stringify(this.buttonStyle)
      )
        this.buttonStyle = buttonStyle;
      // console.log("last return", JSON.stringify(this.buttonStyle));
    },
    debounceUpdateCustomPadding: debounce(async function() {
      await this.updateCustomPadding();
    }, 100)
  },
  mounted() {
    this.updateCustomPadding();
  },
  watch: {
    scrollNow() {
      this.updateCustomPadding();
    },
    footerBoundingSize() {
      this.updateCustomPadding();
    }
    // buttonStyle(val) {
    //   console.log(JSON.stringify(val));
    // }
  }
};
</script>

<style lang="scss">
$main-color: theme-color("secondary");

.to-top {
  $size-top-btn: 40px;
  $padding-btn: 15px;

  font-size: 22px;
  font-weight: 300;
  color: $main-color;
  position: fixed;
  bottom: $padding-btn;
  left: $padding-btn;
  text-decoration: none;
  z-index: 99999;
  transition: padding-bottom 150ms;
  //filter: drop-shadow(0 0 5px $body-bg);

  .button {
    display: flex;
    border: 1px solid $main-color;
    background: $body-bg;
    border-radius: 50%;
    height: $size-top-btn;
    width: $size-top-btn;

    & > div {
      height: 100%;
      width: 100%;

      * {
        stroke: $main-color;
      }
    }
  }
}
</style>
