<template>
  <div class="footer" ref="footer">
    <slot></slot>
    <div class="container g-0">
      <div class="row justify-content-between align-items-center g-0">
        <div class="col-12 col-md-4 text-center text-md-start">
          {{ $t("about.university") }}
        </div>
        <div class="year col-12 col-md-2 text-center">
          {{ yearNow }}
        </div>
        <div class="col-12 col-md-4 text-center text-md-end">
          {{ $t("about.organization") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollMixin from "@/mixins/scrollMixin";

export default {
  name: "Footer",
  computed: {
    yearNow() {
      return new Date().getFullYear();
    }
  },
  mixins: [scrollMixin],
  watch: {
    scrollNow() {
      this.footerBoundingSize =
        this.$refs.footer?.getBoundingClientRect() || null;
    }
  }
};
</script>

<style lang="scss">
.footer {
  position: relative;
  background: theme-color("secondary");
  padding: 19px 0;
  font-weight: 300;
  font-size: 16px;
  color: theme-color("light");

  @include media-breakpoint-down("md") {
    font-size: 0.8rem;
  }

  .year {
    font-size: 20px;

    @include media-breakpoint-down("md") {
      font-size: 1rem;
    }
  }
}
</style>
