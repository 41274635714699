<template>
  <div
    class="d-flex justify-content-center w-100"
    :class="{ container: withContainer }"
  >
    <div class="middle-container mb-2"><slot name="default"></slot></div>
  </div>
</template>

<script>
export default {
  name: "MiddleContainer",
  props: {
    withContainer: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss">
.middle-container {
  min-width: $middle-container-min;
  width: $middle-container;
}
</style>
