<template>
  <div class="header">
    <div class="container-lg">
      <div class="site-name">
        <router-link :to="{ name: 'Home' }"
          ><span class="main-part">REG</span>.bmstu
        </router-link>
      </div>
      <div class="burger" @click="() => (mobileMenu = !mobileMenu)">
        <SvgIcon height="20" color="current" icon="bars" />
      </div>
      <transition name="short-fade">
        <div
          class="backdrop-menu"
          @click="() => (mobileMenu = !mobileMenu)"
          v-if="mobileMenu"
        ></div>
      </transition>
      <div class="menu" :class="{ opened: mobileMenu }">
        <div class="close" @click="() => (mobileMenu = !mobileMenu)">
          <SvgIcon height="20" color="current" icon="close" />
        </div>
        <ul class="mb-0">
          <li>
            <a href="#" @click.prevent="isRu = !isRu">{{ buttonLabel }}</a>
          </li>
          <!--          <li>-->
          <!--            <router-link :to="{ name: 'Home' }">{{-->
          <!--              $t("header.events")-->
          <!--            }}</router-link>-->
          <!--          </li>-->
          <li>
            <router-link :to="{ name: 'Contacts' }">{{
              $t("header.contacts")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Instruction' }">{{
              $t("header.instruction")
            }}</router-link>
          </li>
          <template v-if="auth">
            <li>
              <a href="#" @click.prevent="logout">{{ $t("header.signOut") }}</a>
            </li>
            <li>
              <router-link
                :to="{ name: 'Profile' }"
                class="d-inline-flex align-items-center"
              >
                {{ $t("header.personalArea") }}
                <span
                  v-if="this.unreadMessages"
                  class="badge bg-light rounded-pill text-primary"
                >
                  {{ this.unreadMessages }}
                </span>
                <avatar
                  :src="user.avatar_small"
                  class="ms-2"
                  size="32px"
                ></avatar>
              </router-link>
            </li>
          </template>
          <template v-else>
            <li>
              <router-link :to="{ name: 'SignUp' }">{{
                $t("header.signUp")
              }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'SignIn' }">{{
                $t("header.signIn")
              }}</router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../base/SvgIcon";
import { mapActions, mapGetters } from "vuex";
import wsMixin from "../../mixins/wsMixin";
import Avatar from "../base/Avatar";
export default {
  name: "Header",
  components: {
    Avatar,
    SvgIcon
  },
  mixins: [wsMixin],
  data: () => ({
    mobileMenu: false
  }),
  computed: {
    ...mapGetters(["auth", "jwt", "user", "language"]),
    isRu: {
      get() {
        return this.language === "ru";
      },
      set(value) {
        this.$store.commit("UPDATE_LANGUAGE_RELOAD", value ? "ru" : "en");
      }
    },
    buttonLabel() {
      return this.isRu ? "english version" : "версия на русском";
    },
    htmlOverflowHidden: {
      get() {
        return this.$store.state.htmlOverflowHidden;
      },
      set(val) {
        this.$store.commit("UPDATE_OVERFLOW", val);
      }
    }
  },
  methods: {
    ...mapActions(["logout"])
  },
  watch: {
    mobileMenu(val) {
      this.htmlOverflowHidden = val;
    },
    $route() {
      this.mobileMenu = false;
    }
  }
};
</script>

<style lang="scss">
$default-header-size: 80px;
$mobile-header-size: $default-header-size * 0.75;

.header {
  overflow: hidden;
  display: flex;
  align-items: center;

  .burger,
  .close {
    cursor: pointer;
  }

  & > div.container-lg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: inline-flex;
      justify-items: center;
      height: auto;
    }

    & > .burger {
      @include media-breakpoint-up("lg") {
        display: none;
      }
    }
  }

  background: theme-color("primary");
  color: theme-color("light");
  height: $default-header-size;
  min-height: $default-header-size;

  @include media-breakpoint-down("md") {
    height: $mobile-header-size;
    min-height: $mobile-header-size;
  }

  .site-name {
    font-size: 45px;

    a {
      text-decoration: none;
      color: inherit;
    }

    .main-part {
      font-weight: bold;
    }
  }

  .backdrop-menu {
    display: none;
  }

  @include media-breakpoint-down("lg") {
    .backdrop-menu {
      @include reg-backdrop;

      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
    }
  }

  .menu {
    & > .close {
      display: none;
    }

    @include media-breakpoint-down("lg") {
      height: 100vh !important;
      padding: 1rem 2rem;
      position: absolute;
      background: theme-color("primary");
      bottom: 0;
      right: 0;
      top: 0;
      z-index: 100;
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;

      & > .close {
        position: absolute;
        display: block;
        top: 1rem;
        right: 1rem;
      }

      & > ul {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        flex-grow: 1;

        & > li {
          text-align: center;
          margin: 0;

          &:not(:last-child) {
            margin-top: 1rem;
          }

          &:not(:first-child) {
            margin-bottom: 1rem;
          }
        }
      }

      &:not(.opened) {
        //display: none;
        transform: translateX(100%);
        opacity: 0;
      }
    }

    & > .lang-switcher {
      display: flex;
      justify-content: center;

      @include media-breakpoint-up("lg") {
        margin-left: 0.5rem;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      & > li {
        & > a {
          text-decoration: none;
          color: inherit;
        }

        display: block;
        font-size: 18px;

        @include media-breakpoint-up("lg") {
          display: inline;

          & > .close {
            display: none;
          }
        }

        &:not(:last-child) {
          text-transform: lowercase;

          @include media-breakpoint-up("lg") {
            margin-right: 1.5rem;
          }
        }

        &:last-child {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
