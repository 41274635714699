<template>
  <div class="header-image">
    <div
      class="img"
      :style="
        headerImage
          ? `background-image: url('${headerImage}'); background-color: #000;`
          : ''
      "
    >
      <div class="darken-wrapper"></div>
      <div class="left-big"></div>
      <div class="right-big"></div>
      <div class="wrapper-text">
        <HeaderTitle></HeaderTitle>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderTitle from "./HeaderTitle";
export default {
  name: "HeaderImage",
  components: { HeaderTitle },
  computed: {
    ...mapGetters(["headerImage"])
  }
};
</script>

<style lang="scss">
.header-image {
  & > .img {
    width: 100%;
    height: 500px;
    max-height: 50vh;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    transition: all 0.3s;
  }

  & > div {
    position: relative;
    overflow: hidden;
    background: theme-color("primary");

    & > div.wrapper-text {
      position: absolute;
      top: 0;
      width: 100%;
      height: 75%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > div.darken-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(theme-color("dark"), 0.19);
    }

    & > div:not(.wrapper-text):not(.darken-wrapper) {
      $height: 9vw;

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: -$height / 2;
      height: $height;
      background: $body-bg;

      &.left-big {
        transform: skewY(5deg);
        background: rgba($body-bg, 0.5);
        //backdrop-filter: blur(5px);
      }

      &.right-big {
        transform: skewY(-5deg);
      }
    }
  }
}
</style>
