<template>
  <MiddleContainer
    with-container
    class="header-title"
    :class="{ 'd-none d-md-flex': $route.meta.withoutImage }"
  >
    <template v-if="headerTitle">
      <MainTitleBold center class="mt-4" v-if="$route.meta.withoutImage">
        {{ headerTitle }}
      </MainTitleBold>
      <div class="image-text" v-else>
        {{ headerTitle }}
      </div>
    </template>
  </MiddleContainer>
</template>

<script>
import MiddleContainer from "../base/MiddleContainer";
import { mapGetters } from "vuex";
import MainTitleBold from "../base/MainTitleBold";
export default {
  name: "HeaderTitle",
  components: { MainTitleBold, MiddleContainer },
  computed: {
    ...mapGetters(["headerTitle"])
  }
};
</script>

<style lang="scss">
.header-title div.image-text {
  text-align: center;
  text-transform: uppercase;
  //width: 100%;
  background: $body-bg;
  color: theme-color("primary");
  font-size: 22px;
  padding: 11px;
  margin: 10px;
  font-weight: bold;

  @include media-breakpoint-down("md") {
    font-size: 1rem;
  }
}
</style>
