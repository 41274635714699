<template>
  <div class="main-layout" :class="{ 'max-vh': $route.meta.withoutScroll }">
    <Header />
    <component :is="headerComponent" />
    <div class="container content my-4 d-flex flex-column">
      <template v-if="!hideAll">
        <not-found v-if="notFound || underDev" />
        <template v-else>
          <title-bold center v-if="loading">{{ $t("loading") }}</title-bold>
          <router-view :class="{ 'd-none': loading }" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.path" />
            </keep-alive>
          </router-view>
        </template>
      </template>
      <title-bold center v-else>{{ $t("loading") }}</title-bold>
    </div>
    <Footer>
      <ToTopButton></ToTopButton>
    </Footer>
    <modal
      name="modal-signin"
      adaptive
      height="auto"
      scrollable
      overlay-transition="short-fade"
      @closed="signInModal = false"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ restorePass ? "Восстановить пароль" : "Вход" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="signInModal = false"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <sign-in-component v-if="signInModal"></sign-in-component>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Header from "@/components/layout_parts/Header";
import HeaderImage from "@/components/layout_parts/HeaderImage";
import HeaderTitle from "@/components/layout_parts/HeaderTitle";
import Footer from "../components/layout_parts/Footer";
import ToTopButton from "../components/layout_parts/ToTopButton";
import SignInComponent from "../components/auth/SignInComponent";
import PrivateInformation from "../components/toasts/PrivateInformation";
import NotFound from "../views/NotFound";
import TitleBold from "../components/base/TitleBold";
import loadingMixin from "../mixins/loadingMixin";

export default {
  name: "MainLayout",
  components: {
    TitleBold,
    NotFound,
    ToTopButton,
    Footer,
    Header,
    SignInComponent
  },
  mixins: [loadingMixin],
  computed: {
    headerComponent() {
      return this.$route.meta.withoutImage ? HeaderTitle : HeaderImage;
    },
    headerImage() {
      return this.$route.meta.headerImage;
    },
    headerTitle() {
      return this.$route.meta.headerTitle;
    },
    signInModal: {
      get() {
        return this.$store.state.auth.signInModal;
      },
      set(val) {
        this.$store.commit("UPDATE_SIGNIN_MODAL", val);
      }
    },
    htmlOverflowHidden: {
      get() {
        return this.$store.state.htmlOverflowHidden;
      },
      set(val) {
        this.$store.commit("UPDATE_OVERFLOW", val);
      }
    },
    restorePass: {
      get() {
        return this.$store.state.restorePass;
      },
      set(val) {
        this.$store.commit("UPDATE_RESTORE_PASS", val);
      }
    }
  },
  watch: {
    signInModal(val) {
      if (val) this.$modal.show("modal-signin");
      else this.$modal.hide("modal-signin");
      this.htmlOverflowHidden = val;
    },
    htmlOverflowHidden(val) {
      if (val) {
        document.querySelector("html").className = "overflow-hidden";
      } else {
        document.querySelector("html").className = "";
      }
    }
  },
  mounted() {
    if (!this.$store.state.privateInformationShowed) {
      this.$toast.info(PrivateInformation, {
        position: "bottom-left",
        timeout: false,
        closeOnClick: false,
        toastClassName: "toast-w-100",
        closeButton: false,
        draggable: false
      });
    }
  }
};
</script>

<style lang="scss">
//.container.content {
//  width: auto !important;
//}

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.max-vh {
    height: 100vh;
  }

  .content {
    flex: 1 0 0;
    position: relative;
  }
}
</style>
